<template>
  <div>
    <!-- Start 404 Page  -->
    <div class="error-page-inner bg_color--3">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="inner">
              <h1 class="heading-title theme-gradient">404!</h1>
              <h3 class="sub-title">ページが見つかりません</h3>
              <span>ご指定のページが見つかりませんでした。</span>
              <div class="error-button">
                <router-link to="/" class="rf-btn"
                  >ホームに戻る</router-link
                >
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>  export default {
    components: {
    },
    data() {
      return {};
    },
  };
</script>

<style lang="scss" scoped></style>
